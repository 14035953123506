import React from 'react'

export default function Aout() {
  return (
    <div className='container'>
      <header>
        <h1>About Us</h1>
    </header>
    <main>
        <section>
            <p>Welcome to Coursewallah.shop, your premier source for a wide range of eBooks. We are dedicated to providing you with the best selection of eBooks, covering diverse genres and topics to cater to every reader's interests.</p>
            <p>At Coursewallah.shop, we believe in the power of knowledge and the joy of reading. Our mission is to make eBooks accessible to everyone, offering a convenient platform to discover, purchase, and enjoy eBooks from the comfort of your home.</p>
            <p>Our collection includes eBooks on technology, fiction, self-help, business, and much more. We carefully curate our selection to ensure high-quality content that enriches and inspires our readers.</p>
            <p>We are committed to providing exceptional customer service and a seamless shopping experience. If you have any questions or feedback, please don't hesitate to contact us at <a href="mailto:ebookswallah2024@gmail.com">ebookswallah2024@gmail.com</a>.</p>
            <p>Thank you for choosing Coursewallah.shop. Happy reading!</p>
        </section>
    </main>
    </div>
  )
}
