import React, { useState, useRef, useEffect } from "react";
import ebooksData from "../Showcasejson/ebooks.json";
import { Link } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import "./ebook.css";
import SimilarProduct from "./SimilarProduct";

const Ebooks = () => {
  const currentURL = window.location.pathname.substring(1);
  const ebooks = ebooksData.ebooks;
  const filteredEbooks = ebooks.filter(
    (ebook) => ebook.url === `${currentURL}`
  )[0];
  const [selectedImage, setSelectedImage] = useState(0);
  const owlRef = useRef(null);
  const previewOwlRef = useRef(null);
  const [previewOwlInitialized, setPreviewOwlInitialized] = useState(false);
  const [mainOwlInitialized, setMainOwlInitialized] = useState(false);
  const [duration, setDuration] = useState(20 * 60 * 60);

  const [zoomedImages, setZoomedImages] = useState(
    filteredEbooks.productImgDesc.map(() => false)
  );

  const owlCarouselOptions = {
    items: 1,
    center: true,
    loop: true,
    margin: 8,
    nav: true, // Show navigation arrows
    navText: [
      "<i class='fa-solid fa-chevron-left'></i>",
      "<i class='fa-solid fa-chevron-right'></i>",
    ], // Customize the arrow icons
    dots: false,
    // autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    onTranslated: (e) => {
      if (previewOwlInitialized && mainOwlInitialized) {
        previewOwlRef.current.to(e.item.index + 2, 0);
      }
    },
    onInitialized: () => {
      setMainOwlInitialized(true);
    },
  };

  const owlCarouselPreview = {
    center: true,
    loop: true,
    margin: 8,
    nav: false,
    dots: false,
    // autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    onTranslated: (e) => {
      if (previewOwlInitialized && mainOwlInitialized) {
        owlRef.current.to(e.item.index + 1, 0);
      }
    },
    onInitialized: () => {
      setPreviewOwlInitialized(true);
    },
  };

  useEffect(() => {
    if (previewOwlInitialized && mainOwlInitialized) {
      owlRef.current.to(selectedImage, 0);
    }
  }, [selectedImage, previewOwlInitialized, mainOwlInitialized]);
  const randomSells = Math.floor(Math.random() * (30 - 10 + 1)) + 10;
  const randomActive = Math.floor(Math.random() * (30 - 10 + 1)) + 10;

  const owlCarouselEachEbook = {
    items: 1,
    center: true,
    loop: true,
    margin: 8,
    nav: true, // Show navigation arrows
    dots: false,
    navText: [
      "<i class='fa-solid fa-chevron-left'></i>",
      "<i class='fa-solid fa-chevron-right'></i>",
    ],
    // autoplay: true, // Enable autoplay
    autoplayTimeout: 3000, // Set autoplay interval in milliseconds
    autoplayHoverPause: true, // Pause autoplay on hover
  };

  // const [timer, setTimer] = useState(20 * 60 * 60); // 20 hours in seconds

  useEffect(() => {
    let timer = duration;
    const interval = setInterval(() => {
      let hours = Math.floor(timer / 3600);
      let minutes = Math.floor((timer % 3600) / 60);
      let seconds = timer % 60;

      hours = hours.toString().padStart(2, "0");
      minutes = minutes.toString().padStart(2, "0");
      seconds = seconds.toString().padStart(2, "0");

      document.getElementById("timer").innerHTML =
        hours + "hrs " + minutes + "mins " + seconds + "sec";

      if (--timer < 0) {
        timer = duration;
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [duration]);

  const showHidetext = (index, buttonText) => {
    const textElements = document.querySelectorAll(`.text-${index}`);
    textElements.forEach((textElement) => {
      if (buttonText.innerHTML === "VIEW ALL") {
        textElement.style.height = "100%";
        buttonText.textContent = "VIEW LESS";
      } else {
        textElement.style.height = "162px";
        buttonText.textContent = "VIEW ALL";
      }
    });
  };
  // function showFakeSalesAlert() {
  //   const alerts = [
  //     "Someone just bought MERN Stack Development eBook!",
  //     "10 people are viewing this eBook right now!",
  //     "Hurry! Limited Time Offer!",
  //     "New customer just purchased MERN Stack Development eBook!",
  //     "Special discount ending soon, don't miss out!",
  //     "Bestseller alert: MERN Stack Development eBook flying off the shelves!",
  //     "Join the hundreds of satisfied customers who have purchased MERN Stack Development eBook!",
  //   ];
  //   const randomAlert = alerts[Math.floor(Math.random() * alerts.length)];
  //   console.log(randomAlert)
  //   const alertElement = document.createElement("div");
  //   alertElement.textContent = randomAlert;
  //   alertElement.classList.add("fakeAlert");

  //   document.body.appendChild(alertElement);

  //   setTimeout(() => {
  //     alertElement.remove();

  //     // Show the next alert after 1 second
  //     setTimeout(() => {
  //       showFakeSalesAlert();
  //     }, 5000);
  //   }, 2000); // Show the alert for 1 second
  // }

  // useEffect(() => {
  //   showFakeSalesAlert(); // Initial call to start showing alerts
  // }, []);

  return (
    <>
      <main className="container">
        {filteredEbooks && (
          <div>
            <div className="bred-cramb d-flex justify-content-between px-lg-0 px-3">
              <div className="title">
                <h2
                  className="fw-bold text-uppercase"
                  style={{ color: "#291d51" }}
                >
                  {filteredEbooks.title}
                </h2>
              </div>
              <div className="bread-cramb-path">
                <p>
                  <Link className="text-decoration-none" to="/">
                    HOME /
                  </Link>{" "}
                  &nbsp;{filteredEbooks.title}
                </p>
              </div>
            </div>
            <div className="product-img-desc d-lg-flex ">
              <div className="product-slider col-lg-6">
                <OwlCarousel
                  {...owlCarouselOptions}
                  className="owl-theme"
                  ref={owlRef}
                >
                  {filteredEbooks.images.map((image, index) => (
                    <div key={index} className="main-image-crousel">
                      <img
                        src={image}
                        alt={` ${index}`}
                        className={index === selectedImage ? "active" : ""}
                      />
                    </div>
                  ))}
                </OwlCarousel>

                <div className="preview-container">
                  <OwlCarousel
                    {...owlCarouselPreview}
                    className="owl-theme"
                    ref={previewOwlRef}
                  >
                    {filteredEbooks.images.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        alt={` ${index}`}
                        className={index === selectedImage ? "selected" : ""}
                      />
                    ))}
                  </OwlCarousel>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="active-ordered d-flex gap-5">
                  <div className="ordered d-flex ">
                    <img
                      src="https://ps-booksbuy.myshopify.com/cdn/shop/t/2/assets/gif3.gif?v=36151821664490370411630474128"
                      alt=""
                    ></img>
                    &nbsp;
                    <span className="fw-bold" style={{ color: "#8e8bf5" }}>
                      {randomSells}
                    </span>
                    &nbsp;
                    <span>Sells in last 20 Hrs</span>
                  </div>
                  <div className="active ">
                    <img
                      src="https://ps-booksbuy.myshopify.com/cdn/shop/t/2/assets/gif.gif?v=82281147735557515521630474127"
                      alt=""
                    ></img>
                    &nbsp;
                    <span className="fw-bold" style={{ color: "#8e8bf5" }}>
                      {randomActive}
                    </span>
                    &nbsp;
                    <span>Active View This eBook</span>
                  </div>
                </div>

                <div className="price-title-off my-3 px-4 pb-2">
                  <p className="d-flex gap-2">
                    <h2
                      className="fw-bold text-uppercase"
                      style={{ color: "#291d51" }}
                    >
                      {filteredEbooks.title}
                    </h2>
                  </p>
                  <p className="m-0 d-flex align-items-center">
                    <h5 style={{ color: "#555" }}>
                      <del>₹{filteredEbooks.actualPrice}</del>
                    </h5>{" "}
                    &nbsp; &nbsp;
                    <h2 className="m-0 fw-bold" style={{ color: "red" }}>
                      ₹{filteredEbooks.price}
                    </h2>
                    <h5 className="mx-3 percentage-off">
                      {filteredEbooks.discountPercentage}% <br />
                      OFF
                    </h5>
                  </p>
                  {filteredEbooks.masterProduct === true && (
                    <div className="ebook-description   text-start">
                      <p
                        className="d-flex text-start justify-content-start hurry-up m-0 my-1"
                        style={{ color: "#5f5f5f" }}
                      >
                        <img
                          className="mx-2"
                          src="http://tinyurl.com/mwehsaa7"
                          alt=""
                        />
                        <h6 className="text-danger fw-bold">Hurry up!</h6>&nbsp;
                        <h6>This offer ends in</h6>&nbsp;
                        <h6 className="text-danger" id="timer"></h6>
                      </p>
                      <p
                        className="d-flex hurry-up m-0 text-start justify-content-start"
                        style={{ color: "#5f5f5f" }}
                      >
                        <img src="http://tinyurl.com/dcwk4k3u" alt="" />
                        <h6 className="fw-bold">Risk-free Purchase:</h6>&nbsp;
                        <h6>Full refund within 2 days</h6>
                      </p>
                      <p
                        className="d-flex hurry-up m-0 my-1 text-start justify-content-start"
                        style={{ color: "#5f5f5f" }}
                      >
                        <img src="http://tinyurl.com/ypydd866" alt="" />
                        <h6 className="fw-bold">Stripe Safe Checkout</h6>
                      </p>
                    </div>
                  )}
                  {filteredEbooks.masterProduct !== true && (
                    <div>
                      <h5>Why Buy This?</h5>
                      <div
                        className="why-buy-this"
                        dangerouslySetInnerHTML={{
                          __html: filteredEbooks.list,
                        }}
                      ></div>
                    </div>
                  )}

                  <div className="d-flex payment-link">
                    <Link
                      className=" text-decoration-none"
                      target="_blank"
                      to={filteredEbooks.paymentLink}
                    >
                      <button>
                        BUY NOW &nbsp; ₹{filteredEbooks.price}
                        <img
                          className="mx-2"
                          src="http://tinyurl.com/y9jp9ctj"
                          alt=""
                        />
                      </button>
                    </Link>
                  </div>
                </div>
                {filteredEbooks.masterProduct !== true && (
                  <div className="ebook-description px-4  ">
                    <p
                      className="d-flex hurry-up m-0 my-1"
                      style={{ color: "#5f5f5f" }}
                    >
                      <img
                        className="mx-2"
                        src="http://tinyurl.com/mwehsaa7"
                        alt=""
                      />
                      <h6 className="text-danger fw-bold">Hurry up!</h6>&nbsp;
                      <h6>This offer ends in</h6>&nbsp;
                      <h6 className="text-danger" id="timer"></h6>
                    </p>
                    <p
                      className="d-flex hurry-up m-0"
                      style={{ color: "#5f5f5f" }}
                    >
                      <img src="http://tinyurl.com/dcwk4k3u" alt="" />
                      <h6 className="fw-bold">Risk-free Purchase:</h6>&nbsp;
                      <h6>Full refund within 2 days</h6>
                    </p>
                    <p
                      className="d-flex hurry-up m-0 my-1"
                      style={{ color: "#5f5f5f" }}
                    >
                      <img src="http://tinyurl.com/ypydd866" alt="" />
                      <h6 className="fw-bold">Stripe Safe Checkout</h6>
                    </p>
                  </div>
                )}
                {filteredEbooks.bonusProduct.length > 0 &&
                  filteredEbooks.bonusProduct[0] !== "" && (
                    <div className="bonus-book text-center">
                      <div className="each-title">
                        <h3 className="fw-bold">+</h3>
                        <h3 className="text-uppercase fw-bold">
                          Free{" "}
                          <span>{filteredEbooks.bonusProduct[0]} eBook</span>
                        </h3>
                      </div>
                      <img src={filteredEbooks.bonusProductImg} alt="" />
                    </div>
                  )}
                {/* <hr className="mx-3" /> */}
                {filteredEbooks.masterProduct === true && (
                  <div className="product-included bg-light mt-4 mx-lg-5 mx-2">
                    <div className="productCount p-4">
                      <h4>{filteredEbooks.productCount} Products Included:</h4>
                    </div>
                    <div className="product-list  overflow-auto mx-lg-5 mx-2 border my-2 rounded p-3">
                      {filteredEbooks.productHeroImg.map((image, index) => (
                        <div
                          key={index}
                          className="d-flex productPriceImg align-items-center justify-content-between"
                        >
                          <div className="productetail d-flex gap-2 align-items-center ">
                            <img src={image} alt={`${index + 1}`} />
                            <h5>{filteredEbooks.product[index]}</h5>
                          </div>
                          <div>
                            <del className="fw-bold">₹129</del>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* <SimilarProduct /> */}
            <div className="each-detail-product my-5">
              <h3 className="text-center each-title">
                About <span>{filteredEbooks.title} Book</span>
              </h3>
              <p
                className="text-center"
                dangerouslySetInnerHTML={{ __html: filteredEbooks.description }}
              ></p>

              {filteredEbooks.masterProduct !== true && (
                <div className="d-lg-flex col-12  justify-content-center p-0 flex-wrap container-fluid">
                  {filteredEbooks.product.map((productName, index) => (
                    <div
                      key={index}
                      className="all-products-lists mx-1 my-2 col-lg-12 my-3"
                    >
                      <div className="product-cards pb-2">
                        <h4>{productName}</h4>

                        <div className="d-lg-flex">
                          <div className="crousel-each-product d-flex justify-content-center col-lg-3">
                            <OwlCarousel
                              {...owlCarouselEachEbook}
                              className="owl-theme"
                              ref={previewOwlRef}
                            >
                              {filteredEbooks.productImgDesc[index].map(
                                (image, imgIndex) => (
                                  <img
                                    key={imgIndex}
                                    src={image}
                                    alt={` ${index}-${imgIndex}`}
                                    className={`zoomable ${
                                      imgIndex === selectedImage
                                        ? "selected"
                                        : ""
                                    } ${
                                      zoomedImages[index] ? "zoomed-in" : ""
                                    }`}
                                    onDoubleClick={() => {
                                      const newZoomedImages = [...zoomedImages];
                                      newZoomedImages[index] =
                                        !newZoomedImages[index];
                                      setZoomedImages(newZoomedImages);
                                    }}
                                  />
                                )
                              )}
                            </OwlCarousel>
                          </div>
                          <div className="col-lg-9">
                            <p className="mt-3">
                              <ul
                                className={`text-${index} mt-3`}
                                style={{ height: "200px", overflow: "auto" }}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    filteredEbooks.productDescription[index],
                                }}
                              ></ul>
                            </p>
                          </div>
                        </div>
                      </div>
                      <button
                        className="btn clickToShowMore"
                        onClick={(e) => {
                          const buttonText =
                            e.currentTarget.querySelector(".viewAll");
                          showHidetext(index, buttonText);
                        }}
                      >
                        <img src="http://tinyurl.com/yjajh5xa" alt=""></img>
                        <br />
                        <span className="viewAll">VIEW ALL</span>
                      </button>

                      <div className="d-flex payment-link">
                        <Link
                          className=" text-decoration-none"
                          target="_blank"
                          to={filteredEbooks.paymentLink}
                        >
                          <button>
                            BUY NOW &nbsp; ₹{filteredEbooks.price}
                            <img
                              className="mx-2"
                              src="http://tinyurl.com/y9jp9ctj"
                              alt=""
                            />
                          </button>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {filteredEbooks.masterProduct !== true && (
                <div className="d-flex payment-link payment-bottom-pc">
                  <Link
                    className=" text-decoration-none"
                    target="_blank"
                    to={filteredEbooks.paymentLink}
                  >
                    <button>
                      BUY NOW &nbsp; ₹{filteredEbooks.price}
                      <img
                        className="mx-2"
                        src="http://tinyurl.com/y9jp9ctj"
                        alt=""
                      />
                    </button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        )}
      </main>

      {filteredEbooks.masterProduct === true && (
        <div>
          <div className="masterProductDetail">
            <div className="container">
              <div className="heading text-center py-5 d-flex gap-1 justify-content-center">
                <h2 className="fw-bold">What we’ve put together</h2>
                <img
                  height={50}
                  src="https://business-explained.com/wp-content/uploads/2023/11/wrapped-gift.png"
                  alt=""
                />
              </div>
              <p className="text-center">
                In this one-of-a-kind All Products Special,{" "}
                <b>
                  we’ve included everything a {filteredEbooks.category} could
                  need:
                </b>
              </p>
              <div className="d-lg-flex d-block gap-5 justify-content-center category">
                <div className="col-12 col-lg-6  product1">
                  <div className="categoryHeading p-lg-5 px-3 pt-4 mt-3 mt-lg-0">
                    <h3>Bundle: {filteredEbooks.subCategory[0]}</h3>
                    <p>
                      Including All{" "}
                      <b>
                        {filteredEbooks.subCategoryCount[0]}{" "}
                        {filteredEbooks.subCategory[0]} eBooks,
                      </b>{" "}
                      covering a wide array of topics from{" "}
                      {filteredEbooks.subCategory[0]}. These eBooks are your
                      comprehensive guide to {filteredEbooks.subCategory[0]}{" "}
                      knowledge.
                    </p>

                    <div className="product-included bg-light mt-4 mx-lg-3 mx-2">
                      <div className="productCount p-4">
                        <h4>
                          {filteredEbooks.subCategoryCount[0]} Products
                          Included:
                        </h4>
                      </div>
                      <div className="product-list  overflow-auto mx-3 border my-2 rounded p-3">
                      {filteredEbooks.categoryHeroImg[0].map(
                          (image, index) => (
                            <div
                              key={index}
                              className="d-flex productPriceImg align-items-center justify-content-between"
                            >
                              <div className="productetail d-flex gap-2 align-items-center">
                                <img src={image} alt={` ${index}`} />
                                <h5>
                                  {filteredEbooks.categoryHerProduct[1][index]}
                                </h5>
                              </div>
                              <div>
                                <del className="fw-bold">₹129</del>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" col-12 col-lg-6 product2">
                  <div className="categoryHeading px-3 pt-4 mt-3 mt-lg-0">
                    <h3>Bundle: {filteredEbooks.subCategory[1]}</h3>
                    <p>
                      Including All{" "}
                      <b>
                        {filteredEbooks.subCategoryCount[1]}{" "}
                        {filteredEbooks.subCategory[1]} eBooks,
                      </b>{" "}
                      covering a wide array of topics from{" "}
                      {filteredEbooks.subCategory[1]}. These eBooks are your
                      comprehensive guide to {filteredEbooks.subCategory[1]}{" "}
                      knowledge.
                    </p>
                    <div className="product-included bg-light mt-4 mx-3">
                      <div className="productCount p-4">
                        <h4>
                          {filteredEbooks.subCategoryCount[1]} Products
                          Included:
                        </h4>
                      </div>
                      <div className="product-list  overflow-auto mx-3 border my-2 rounded p-3">
                        {filteredEbooks.categoryHeroImg[1].map(
                          (image, index) => (
                            <div
                              key={index}
                              className="d-flex productPriceImg align-items-center justify-content-between"
                            >
                              <div className="productetail d-flex gap-2 align-items-center">
                                <img src={image} alt={` ${index}`} />
                                <h5>
                                  {filteredEbooks.categoryHerProduct[1][index]}
                                </h5>
                              </div>
                              <div>
                                <del className="fw-bold">₹129</del>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container profuctFor py-5 mt-lg-0 mt-4">
              <div className="text-center p-lg-0 px-3">
                <h2 className="fw-bold">
                  Who is it for <span className="text-danger">?</span>
                </h2>
                <p>
                  The All Products Special offer is perfect for a wide range of{" "}
                  <b>tech-minded individuals</b> – from hustlers and
                  entrepreneurs to managers and business enthusiasts – and
                  anyone eager to take their business to the next level.
                </p>
              </div>
              <div className=" who-for-inner d-lg-flex d-block px-lg-0 px-4 my-lg-0 my-5">
                <div className="hustler d-flex col-lg-4 col-12 gap-2 my-lg-0 my-3 ">
                  <img
                    className="hustlerImg"
                    src="https://business-explained.com/wp-content/uploads/2023/11/Hustlers.png"
                    alt=""
                  />
                  <div className="textPlusIcon">
                    <div className="d-flex gap-2">
                      <h3 className="">Hustlers</h3>
                      <img
                        className="icon"
                        src="https://business-explained.com/wp-content/uploads/2023/11/flexed-biceps.png"
                        alt=""
                      />
                    </div>
                    <div className="aboutText">
                      <p className="m-0">
                        Driven go-getters seeking practical tech tactics.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="hustler d-flex col-lg-4 col-12 gap-2 my-lg-0 my-3 ">
                  <img
                    className="hustlerImg"
                    style={{
                      background:
                        "linear-gradient(137deg, #304cd8 21.04%, #7285e6 32.19%, #f4f6ff 86.88%)",
                    }}
                    src="https://business-explained.com/wp-content/uploads/2023/11/Self-developers.png"
                    alt=""
                  />
                  <div className="textPlusIcon">
                    <div className="d-flex gap-2">
                      <h3 className="" style={{ color: "#304cd8" }}>
                        Self-developers
                      </h3>
                      <img
                        className="icon"
                        src="https://business-explained.com/wp-content/uploads/2023/11/LapTop-emoji.png"
                        alt=""
                      />
                    </div>
                    <div className="aboutText">
                      <p className="m-0">
                        Individuals enhancing skills for personal & professional
                        growth.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="hustler d-flex col-lg-4 col-12 gap-2 my-lg-0 my-3 ">
                  <img
                    className="hustlerImg"
                    style={{
                      background:
                        "linear-gradient(137deg,#ff5b1a 21.1%,#ffb192 49.66%,#fff4f0 87.49%)",
                    }}
                    src="https://business-explained.com/wp-content/uploads/2023/11/Entrepeneur.png"
                    alt=""
                  />
                  <div className="textPlusIcon">
                    <div className="d-flex gap-2">
                      <h3 className="" style={{ color: "#ff5b1a" }}>
                        Entrepreneurs
                      </h3>
                      <img
                        className="icon"
                        src="https://business-explained.com/wp-content/uploads/2023/11/trophy.png"
                        alt=""
                      />
                    </div>
                    <div className="aboutText">
                      <p className="m-0">
                        Visionaries building businesses from the ground up.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="hustler d-flex col-lg-4 col-12 gap-2 my-lg-0 my-3 ">
                  <img
                    className="hustlerImg"
                    style={{
                      background:
                        "linear-gradient(137deg,#f349fc 21.04%,#fa9fff 40.34%,#fef2ff 86.88%)",
                    }}
                    src="https://business-explained.com/wp-content/uploads/2023/11/Business-Enthusiasts.png"
                    alt=""
                  />
                  <div className="textPlusIcon">
                    <div className="d-flex gap-2">
                      <h3 className="" style={{ color: "#f349fc" }}>
                        Computer Enthusiasts{" "}
                      </h3>
                      <img
                        className="icon"
                        src="https://business-explained.com/wp-content/uploads/2023/11/Magnifying-glass-emoji.png"
                        alt=""
                      />
                    </div>
                    <div className="aboutText">
                      <p className="m-0">
                        Keen learners passionate about Computer Science.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="hustler d-flex col-lg-4 col-12 gap-2 my-lg-0 my-3 ">
                  <img
                    className="hustlerImg"
                    style={{
                      background:
                        "linear-gradient(137deg,#ffc000 21.04%,#ffe082 42.05%,#fffdf9 86.88%)",
                    }}
                    src="https://business-explained.com/wp-content/uploads/2023/11/Managers.png"
                    alt=""
                  />
                  <div className="textPlusIcon">
                    <div className="d-flex gap-2">
                      <h3 className="" style={{ color: "#ffc000" }}>
                        Managers
                      </h3>
                      <img
                        className="icon"
                        src="https://business-explained.com/wp-content/uploads/2023/11/Lightbulb-emoji.png"
                        alt=""
                      />
                    </div>
                    <div className="aboutText">
                      <p className="m-0">
                        Leaders aiming to optimize team performance &
                        strategies.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="hustler d-flex col-lg-4 col-12 gap-2 my-lg-0 my-3 ">
                  <img
                    className="hustlerImg"
                    style={{
                      background:
                        "linear-gradient(136deg,#b3e120 21.05%,#d3ef7b 34.06%,#fefffa 86.95%)",
                    }}
                    src="https://business-explained.com/wp-content/uploads/2023/11/Fresh-employee.png"
                    alt=""
                  />
                  <div className="textPlusIcon">
                    <div className="d-flex gap-2">
                      <h3 className="" style={{ color: "#b3e120" }}>
                        Fresh Employees{" "}
                      </h3>
                      <img
                        className="icon"
                        src="https://business-explained.com/wp-content/uploads/2023/11/Notes-Emoji.png"
                        alt=""
                      />
                    </div>
                    <div className="aboutText">
                      <p className="m-0">
                        Newcomers keen on mastering Programming fundamentals.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-banner container">
              <div className="d-lg-flex p-lg-5 px-3 pt-lg-0">
                <div className="offerDiscount col-lg-6 col-12 pt-5">
                  <div className="offerHeading">
                    <h2 className="text-white ">
                      How to save ₹1,806 today?{" "}
                      <img
                        width={50}
                        src="https://business-explained.com/wp-content/uploads/2023/11/wrapped-gift.png"
                        alt=""
                      />{" "}
                    </h2>
                    <p className="text-white">
                      If you love saving money, this is for you. Grab our full
                      bundle for just <b>₹{filteredEbooks.price}</b> that saves
                      you ₹1,806 compared to buying each product separately!
                    </p>

                    <div className="d-flex payment-link">
                      <Link
                        className=" text-decoration-none"
                        target="_blank"
                        to={filteredEbooks.paymentLink}
                      >
                        <button>
                          BUY NOW &nbsp; ₹{filteredEbooks.price}
                          <img
                            className="mx-2"
                            src="http://tinyurl.com/y9jp9ctj"
                            alt=""
                          />
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="CreativeImg">
                  <img src="https://tinyurl.com/mr38tr86" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Ebooks;
