import React from 'react'

export default function Terms_condition() {
  return (
    <div className='container'>
        <header>
        <h1>Terms and Conditions</h1>
    </header>
    <main>
        <section>
            <h2>1. Introduction</h2>
            <p>These Terms and Conditions govern your use of the Coursewallah.shop website. By accessing or using the website, you agree to be bound by these Terms and Conditions.</p>

            <h2>2. Use of the Website</h2>
            <p>You may use the website for your personal, non-commercial purposes only. You must not use the website in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of the website.</p>

            <h2>3. Intellectual Property Rights</h2>
            <p>All content on this website, including text, graphics, logos, and images, is the property of Coursewallah.shop and is protected by copyright laws. You may not reproduce, modify, or distribute any content from this website without prior written permission.</p>

            <h2>4. Limitation of Liability</h2>
            <p>Coursewallah.shop shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of this website.</p>

            <h2>5. Governing Law</h2>
            <p>These Terms and Conditions are governed by and construed in accordance with the laws of [Your Country], and you irrevocably submit to the exclusive jurisdiction of the courts in that location.</p>

            <h2>6. Changes to Terms and Conditions</h2>
            <p>Coursewallah.shop reserves the right to modify or replace these Terms and Conditions at any time. Your continued use of the website after any such changes constitutes your acceptance of the new Terms and Conditions.</p>

            <h2>7. Contact Us</h2>
            <p>If you have any questions or concerns about these Terms and Conditions, please contact us at <a href="mailto:ebookswallah2024@gmail.com">ebookswallah2024@gmail.com</a>.</p>
        </section>
    </main>
    </div>
  )
}
