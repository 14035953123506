import React from 'react'

export default function Refundpolicy() {
  return (
    <div className='container'>
          <header>
        <h1>Refund Policy</h1>
    </header>
    <main>
        <section>
            <h2>1. Refund Policy Overview</h2>
            <p>We want you to be satisfied with your purchase. If you are not completely satisfied, you may request a refund within 7 days of your purchase date.</p>

            <h2>2. Eligibility for Refund</h2>
            <p>To be eligible for a refund, your item must be unused and in the same condition that you received it. It must also be in the original packaging.</p>

            <h2>3. How to Request a Refund</h2>
            <p>To request a refund, please contact us at <a href="mailto:ebookswallah2024@gmail.com">ebookswallah2024@gmail.com</a> with your order details and reason for the refund request. We will review your request and process your refund if it meets our policy.</p>

            <h2>4. Refund Processing</h2>
            <p>Once your refund request is approved, we will initiate a refund to your original method of payment. You will receive the credit within a certain amount of days, depending on your card issuer's policies.</p>

            <h2>5. Contact Us</h2>
            <p>If you have any questions or concerns about our Refund Policy, please contact us at <a href="mailto:ebookswallah2024@gmail.com">ebookswallah2024@gmail.com</a>.</p>
        </section>
    </main>
    </div>
  )
}
