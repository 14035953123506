import React from 'react'

export default function Contactus() {
  return (
    <div className='container'>
        <header>
        <h1>Contact Us</h1>
    </header>
    <main>
        <section>
            <h2>Email</h2>
            <p>Contact us via email at: <a href="mailto:ebookswallah2024@gmail.com
">ebookswallah2024@gmail.com
</a></p>
            <h2>Phone</h2>
            <p>Call us at: +91 7042447890</p>
            
        </section>
    </main>
    </div>
  )
}
