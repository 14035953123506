import "./App.css";
import Navbar from "./Component/navbar/Navbar";
import Topshow from "./Component/Homeshow/Topshow";
import React, { useEffect } from "react";
import Showcase from "./Component/showcase/Showcase";
import Footer from "./Component/footer/footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import About from "./Component/Include/About";
import Contactus from "./Component/Include/Contactus";
import Privacypolicy from "./Component/Include/Privacypolicy";
import Termscondition from "./Component/Include/Terms_condition";
import Refundpolicy from "./Component/Include/Refundpolicy";
import ebookjson from "./Component/Showcasejson/ebooks.json";
import Ebooks from "./Component/product/Ebooks";
import Ncrredirect from "./Component/Ncrblock/ncrblock";


function App() {
  const ebooks = ebookjson.ebooks;
  const UrlList = new Set(ebooks.map((ebook) => ebook.url));
  const Urls = Array.from(UrlList);
  console.log(Urls);

  const currentURL = window.location.pathname.substring(1);
  const ebooksTitle = ebookjson.ebooks;
  const filteredEbooks = ebooks.filter(
    (ebook) => ebook.url === `${currentURL}`
  )[0];

  const images = [
    {
      url: "https://encrypted-tbn3.gstatic.com/shopping?q=tbn:ANd9GcSBmRX_hPWxeXGgaVNvGgO4fySEBvWrCV_mPnqnpK7hY59oKt1mIsb5xGJxFTmvjrnxirBDg1x4Zox-9K8Qij6xbYK_uBjCoSY4UKKRBLluG6TbkcWikSgZrg&usqp=CAE",
      offer: "20%",
      desc: "Unlock the full potential of the MERN <span>(MongoDB, Express.js, React, Node.js)</span> stack with our comprehensive collection of eBooks. Whether you're a beginner or an experienced developer, dive deep into the world of MERN development with our expertly crafted guides. Master essential concepts, learn best practices, and build powerful, modern web applications. Get instant access to our eBooks and start your MERN development journey today!",
    },
    {
      url: "https://encrypted-tbn3.gstatic.com/shopping?q=tbn:ANd9GcSBmRX_hPWxeXGgaVNvGgO4fySEBvWrCV_mPnqnpK7hY59oKt1mIsb5xGJxFTmvjrnxirBDg1x4Zox-9K8Qij6xbYK_uBjCoSY4UKKRBLluG6TbkcWikSgZrg&usqp=CAE",
      offer: "30%",
      desc: "Unlock the full potential of the MERN <span>(python,c++,c)</span> stack with our comprehensive collection of eBooks. Whether you're a beginner or an experienced developer, dive deep into the world of MERN development with our expertly crafted guides. Master essential concepts, learn best practices, and build powerful, modern web applications. Get instant access to our eBooks and start your MERN development journey today!",
    },
    // ... add more images
  ];

  return (
    <>
      <Router>
        <Navbar />
        {/* <Ncrredirect/> */}
        <Routes>
          <Route
            exact
            path="/"
            element={
              <div>
                <Helmet>
                  <title>Coursewallah - Home</title>
                </Helmet>

                <Topshow images={images} />
                <Showcase />
              </div>
            }
          />
          <Route
            exact
            path="/about"
            element={
              <div>
                <Helmet>
                  <title>Coursewallah - About</title>
                </Helmet>
                <About />
              </div>
            }
          />
          <Route
            exact
            path="/contact-us"
            element={
              <div>
                <Helmet>
                  <title>Coursewallah - Contact Us</title>
                </Helmet>
                <Contactus />
              </div>
            }
          />
          <Route
            exact
            path="/privacy-policy"
            element={
              <div>
                <Helmet>
                  <title>Coursewallah - Contact Us</title>
                </Helmet>
                <Privacypolicy />
              </div>
            }
          />
          <Route
            exact
            path="/terms-and-conditions"
            element={
              <div>
                <Helmet>
                  <title>Coursewallah - Contact Us</title>
                </Helmet>
                <Termscondition />
              </div>
            }
          />
          <Route
            exact
            path="/refund-policy"
            element={
              <div>
                <Helmet>
                  <title>Coursewallah - Contact Us</title>
                </Helmet>
                <Refundpolicy />
              </div>
            }
          />
          {Urls.map((url, index) => (
            <Route
              key={index}
              exact
              path={`/${url}`} 
              element={
                <div>
                  <Helmet>
                  <title>{`Coursewallah - ${filteredEbooks && filteredEbooks.title}`}</title>
                    <meta name="description" content={filteredEbooks && (filteredEbooks.description)} />
                  </Helmet>
                  <Ebooks />
                </div>
              }
            />
          ))}
          <Route
            path="*"
            element={
              <div>
                <Helmet>
                  <title>404 Not Found</title>
                </Helmet>
                <h1>404 Not Found</h1>
                <p>The page you're looking for could not be found.</p>
              </div>
            }
          />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
