import React from 'react'

export default function Privacypolicy() {
  return (
    <div className='container'>
           <header>
        <h1>Privacy Policy</h1>
    </header>
    <main>
        <section>
            <h2>Introduction</h2>
            <p>This Privacy Policy describes how Coursewallah.shop collects, uses, and protects your personal information when you use our website.</p>

            <h2>Information We Collect</h2>
            <p>We may collect personal information such as your name, email address, and other contact details when you sign up for our services or interact with our website.</p>

            <h2>How We Use Your Information</h2>
            <p>We use your personal information to provide and improve our services, communicate with you, and personalize your experience on our website.</p>

            <h2>Information Sharing and Disclosure</h2>
            <p>We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as described in this Privacy Policy or as required by law.</p>

            <h2>Security</h2>
            <p>We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure.</p>

            <h2>Changes to This Privacy Policy</h2>
            <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

            <h2>Contact Us</h2>
            <p>If you have any questions or concerns about our Privacy Policy, please contact us at <a href="mailto:ebookswallah2024@gmail.com">ebookswallah2024@gmail.com</a>.</p>
        </section>
    </main>
    </div>
  )
}
