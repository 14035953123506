import React, { useState } from 'react';
import ebooksData from '../Showcasejson/ebooks.json';
import './Showcase.css';
import { Link } from 'react-router-dom';

export default function Showcase() {
  const ebooks = ebooksData.ebooks;
  const categoriesSet = new Set(ebooks.map((ebook) => ebook.category));
  const categories = Array.from(categoriesSet);
  const [selectedCategory, setSelectedCategory] = useState(categories[0]); // Default to first category

  const filteredEbooks = ebooks.filter((ebook) => ebook.category === selectedCategory);

  

  return (
    <div className="showcase py-5">
      <div className='d-lg-flex container'>
        <div className='col-lg-4 '>
        <div>
          <div className='week-deals-desc col-lg-8'>
              <h2>Deals Of The Week Buy Now! Hurry</h2>
              <p style={{ color: '#555' }}>I’ll walk you through how to write a book "description", provide you a template and include</p>
          </div>
          <div className='deals-img'>
          <div className='shop-btn'>
            <p>I’ll walk you through how to write a book "description", provide you a template and include</p>
            <button>SHOP NOW</button>
            </div>
          </div>
          </div>
        </div>
        
        <div className='col-lg-8 col-12 mt-lg-0 mt-5'>
        <div className='category-nav d-flex justify-content-left flex-wrap'>
            {categories.map((category, index) => (
              <h3
                key={index}
                className={selectedCategory === category ? 'active-category mx-1' : 'mx-1'}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </h3>
            ))}
          </div>
          <div className='d-flex flex-wrap justify-content-center'>
            {filteredEbooks.map((ebook, index) => (
              <div className='ebooks-card my-2 mx-1 ' key={index}>
              <Link to={ebook.url} className='text-decoration-none'>
                <h3 className='sale'>SALE</h3>
                <img src={ebook.thumbnail} alt=''/>
                
                <h6 className='text-center my-2' style={{ color: '#555' }}>{ebook.title}</h6>
                <div className='text-center get-ebooks-info'>
                  <div className='border-0 d-flex align-items-center my-2'>
                    <h4 className='fw-bold mx-1'>₹{ebook.price}</h4>
                    <h6 className='mx-1' ><del>₹{ebook.actualPrice}</del></h6>
                  </div>
                </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
