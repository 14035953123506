import React from 'react'
import './footer.css'

export default function footer() {
  return (
    <div className='mt-5 container text-center  footer'>
        {/* <p>Welcome to Coursewallah.shop, your one-stop destination for a wide range of eBooks! Explore our collection of eBooks covering various genres and topics, including technology, fiction, self-help, and more. Whether you're looking for educational resources, entertaining reads, or insightful content, we have something for everyone. Browse through our selection, find your next great read, and start your eBook journey with Coursewallah.shop today!</p> */}
        <hr className='m-0'/>
        <p>Copyright © 2024 | Coursewallah.shop | All Rights Reserved</p>
    </div>
  )
}
