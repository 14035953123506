import React from "react";
import { BrowserRouter as   Route ,Link, useLocation} from "react-router-dom";
import "./Navbar.css"

export default function Navbar() {
  return (
    <div className="bg-white">
    <div className="navbar-container bg-white">
      
      <nav className="container navbar navbar-expand-lg navbar- bg-white">
  <div className="container-fluid">
    <Link className="navbar-brand d-flex align-items-center col-4"  to="/" aria-current="page">
    <img src="http://tinyurl.com/yr8rmxca" height="60" width="60" alt="COURSEWALLAH.SHOP"/>
    <h3 className="mx-3 brand-name">COURSEWALLAH.SHOP</h3>
    </Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
  <span className="navbar-toggler-icon"></span>
</button>

    <div className="collapse navbar-collapse col-8" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0 w-100 justify-content-end">
        <li className="nav-item">
          <Link className="nav-link "  to="/about">About Us</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/contact-us">Contact Us</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/privacy-policy">Privacy Policy</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/terms-and-conditions">Terms & Conditions </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/refund-policy">Refund Policy</Link>
        </li>
      
       
      </ul>
     
    </div>
  </div>
</nav>

    </div>

      
    </div>
  );
}
