// import React, { useEffect } from 'react'
import "./topshow.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';


export default function Topshow(props) {
 
  // useEffect(() => {
  //   const razorpay=document.getElementById("razorpay");
  //   const script = document.createElement('script');
  //   script.src = 'https://checkout.razorpay.com/v1/payment-button.js';
  //   script.setAttribute('data-payment_button_id', 'pl_NbaAJkdscRCAhk');
  //   script.async = true;
  //   razorpay.appendChild(script);

  //   return () => {
  //     razorpay.removeChild(script);
  //   };
  // }, []);
  const owlCarouselOptions = {
    items: 1,
    center: true,
    loop: true,
    margin: 8,
    autoplay: true, // Enable autoplay
    autoplayTimeout: 3000, // Set autoplay interval in milliseconds
    autoplayHoverPause: true, // Pause autoplay on hover
  };
  return (
    <div className="topshow-crousel-div">
      <OwlCarousel {...owlCarouselOptions} className="owl-theme">
        {props.images.map((image, index) => (
            <div key={index} className='crousel-div container'> 
                      <div className='d-flex product-area'>
                        <div className='about-product col-6 justify-content-start d-grid align-items-center'>
                          <div className='small-prod-desc'>
                             <div className='welcome-offer'>
                              <h3>Welcome Offer {image.offer} off</h3>
                              <h2 className='text-dark'>Buy now to get <span>{image.offer} Off</span> Instantly</h2>
                              <div className="d-lg-block d-none" dangerouslySetInnerHTML={{ __html: image.desc }} />
                              <button className="my-4">SHOP NOW</button>
                             </div> 
                          </div>
                        </div>
                        <div className='product-images col-6 py-2 text-end justify-content-end d-flex align-items-center'> <img className="img" src={image.url} alt="" /></div>
                      </div>    
          </div>
          
        ))}
      </OwlCarousel>
     
    </div>
    
  );
}
